import MoreVertIcon from "@mui/icons-material/MoreVert";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Snackbar from "@mui/material/Snackbar";
import Tooltip from "@mui/material/Tooltip";
import React, { useEffect, useState } from "react";
import * as versionServices from "../services/version.services";
import "./VersionDropdown.css";
import { v4 as uuidv4 } from "uuid";
const Modal = ({ isOpen, onClose, onSave, existingVersionName }) => {
  const [versionName, setVersionName] = useState(existingVersionName || ""); // Use existing version name for editing
  const [annotations, setAnnotations] = useState({ pen: {}, marker: {} });

  useEffect(() => {
    setVersionName(existingVersionName || ""); // Update version name when modal opens
  }, [existingVersionName, isOpen]);

  const handleSave = () => {
    onSave(versionName);
    setVersionName(""); // Clear input after save
  };

  const handleSaveAnnotations = () => {
    // Handle saving annotations
  };

  if (!isOpen) return null; // Don't render modal if not open

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>{existingVersionName ? "Edit Version" : "Create New Version"}</h2>
        <input
          type="text"
          placeholder="Enter version name"
          value={versionName}
          onChange={(e) => setVersionName(e.target.value)}
        />
        <button onClick={handleSave} className="save-version-button" disabled={!versionName}>
          Save version name
        </button>
        <button onClick={onClose} className="save-version-button">Cancel</button>
      </div>
    </div>
  );
};

// Delete Modal Component
const DeleteModal = ({
  isOpen,
  onClose,
  onDelete,
  versionName,
  annotations,
  onLoadVersion,
}) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Confirm Deletion</h2>
        <p>
          Are you sure you want to delete this version? This action cannot be
          undone.
        </p>
        <button onClick={onDelete}>Delete</button>
        <button onClick={onClose}>Cancel</button>
      </div>
    </div>
  );
};

const VersionDropdown = ({pdfURL, propPageNumber, userId, bookId, onLoadVersion, annotations }) => {
  const [versions, setVersions] = useState([]); // State to hold versions
  const [error, setError] = useState(null); // State to hold any error
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const [selectedVersionId, setSelectedVersionId] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false); // State for Snackbar open status
  const [snackbarMessage, setSnackbarMessage] = useState(""); // State for Snackbar message
  const [selectedVersionData, setSelectedVersionData] = useState(null); // State to hold selected version data
  const [isEditing, setIsEditing] = useState(false); // State for managing edit mode
  const [selectedVersionName, setSelectedVersionName] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [pages, setPages] = useState([]); // For pages if needed
  const [menuAnchor, setMenuAnchor] = useState(null); // A
  const [isCached, setIsCached] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    const fetchVersions = async () => {
      try {
        const response = await versionServices.getAllVersions(userId); // Fetching versions
        console.log("Fetched versions:", response);
        if (Array.isArray(response.data)) {
          setVersions(response.data);
        } else {
          setVersions([]); // If no data is found, set to an empty array
        }
      } catch (err) {
        setError(err); // Setting any error
        console.error("Error fetching versions:", err);
      }
    };

    fetchVersions(); // Call the fetch function on component mount
  }, [userId]); // Empty dependency array to run only once

  const handleMenuOpen = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  useEffect(() => {
    const checkIfCached = async (versionId) => {
      const cache = await caches.open('appV1');
      const cachedResponse = await cache.match(versionId);
      setIsCached(!!cachedResponse);
    };

    if (selectedVersionId) {
      checkIfCached(selectedVersionId);
    }
  }, [selectedVersionId]);

  useEffect(() => {
    const checkIfCached = async (versionId) => {
      const cache = await caches.open('appV1');
      const cachedResponse = await cache.match(versionId);
      setIsCached(!!cachedResponse);
    };

    if (selectedVersionId) {
      checkIfCached(selectedVersionId);
    }
  }, [selectedVersionId]);

  // Save new version
  const handleSave = async (newVersionName) => {
    console.log(annotations);
    // Constructing the version data as expected by the server
    const versionData = {
      version: {
        user_id: userId, // Dynamic user ID
        book_id: bookId, // Dynamic book ID
        version_name: newVersionName, // New version name from input
        pages: annotations ? annotations : undefined,

        isActive: true,
      },
    };

    try {
      const result = await versionServices.createVersion(versionData);
      console.log("Version created:", result);
      // Update versions state with the new version
      setVersions((prevVersions) => [...prevVersions, result.data]);
    } catch (error) {
      console.error("Error saving version:", error.response.data);
    } finally {
      setIsModalOpen(false);
    }
  };

  const handleSaveAnnotations = async () => {
    if (!selectedVersionId) return;
    const updatedVersionData = {
      version: {
        user_id: userId,
        book_id: bookId,
        version_name: selectedVersionName, // Maintain existing name if not changing
        pages: annotations,
        isActive: true,
      },
    };

    try {
      const res = await versionServices.updateVersion(
        selectedVersionId,
        updatedVersionData
      ); // Assuming you have an update function
      if (res.error) {
        setSnackbarMessage("Error updating annotations.");
        setSnackbarOpen(true);
      }
      else {
        setSnackbarMessage("Annotations updated successfully!");
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage("Error updating annotations.");
      setSnackbarOpen(true);
    }
  };

  // Select version by id
  const handleVersionSelect = async (e) => {
    const selectedId = e.target.value;
    setSelectedVersionId(selectedId);
    const versionName = versions.find((version) => version._id === selectedId);
    setSelectedVersionName(versionName.version_name);
    if (selectedId) {
      try {
        const response = await versionServices.getVersionById(selectedId);
        const versionData = response.data;

        // Transform the data to match the required structure
        const formattedAnnotations = versionData.pages.map((page) => ({
          page_index: page.page_index,
          annotations: {
            pen: {
              strokes: page.annotations.pen.strokes || [],
              color: page.annotations.pen.color || "black",
            },
            marker: {
              strokes: page.annotations.marker.strokes || [],
              color: page.annotations.marker.color || "yellow",
            },
            pageMask: {
              height: {
                Top: page.annotations.pageMask?.height?.Top || 0,
                Bottom: page.annotations.pageMask?.height?.Bottom || 0,
              },
            },
            text:
              page.annotations.text?.map((textAnno) => ({
                text: textAnno.content,
                position: textAnno.position,
                color: textAnno.color,
                id: textAnno.id,
              })) || [],
            comment:
              page.annotations.comment?.map((commentAnno) => ({
                text: commentAnno.content,
                position: commentAnno.position,
                id: commentAnno.id,
              })) || [],
            selectionMask:
              page.annotations.selectionMask?.map((selectionMaskAnno) => ({
                x: selectionMaskAnno.x,
                y: selectionMaskAnno.y,
                width: selectionMaskAnno.width,
                height: selectionMaskAnno.height,
                id: selectionMaskAnno.id,
                color: selectionMaskAnno.color,
              })) || [],
          },
        }));


        onLoadVersion(formattedAnnotations);
      } catch (error) {
        console.error("Error fetching version details:", error);
      }
    }
  };

  // Delete version by id
  const handleDelete = async () => {
    if (!selectedVersionId) return;

    try {
      await versionServices.deleteVersion(selectedVersionId);
      setVersions((prevVersions) =>
        prevVersions.filter((version) => version._id !== selectedVersionId)
      );
      setSelectedVersionId("");
      setSnackbarMessage("Version deleted successfully!");
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage("Error deleting version.");
      setSnackbarOpen(true);
    } finally {
      setIsDeleteModalOpen(false); // Close delete modal after delete
    }
  };

  // close notification
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleUpdate = async (updatedVersionName) => {
    if (!selectedVersionId) return;
    // Construct the updated version data
    const updatedVersionData = {
      version: {
        user_id: userId, // Use the dynamic userId
        book_id: bookId, // Use the dynamic bookId
        version_name: updatedVersionName,
        pages: annotations,
        isActive: true,
      },
    };

    try {
      await versionServices.updateVersion(
        selectedVersionId,
        updatedVersionData
      ); // Update version API call
      setVersions((prevVersions) =>
        prevVersions.map((version) =>
          version._id === selectedVersionId
            ? { ...version, version_name: updatedVersionName }
            : version
        )
      );
      setSnackbarMessage("Version updated successfully!");
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage("Error updating version.");
      setSnackbarOpen(true);
    } finally {
      setIsEditing(false); // Reset edit mode
      setIsModalOpen(false); // Close modal after save
    }
  };

  // Duplicate version
  const handleDuplicateVersion = async () => {
    if (!selectedVersionId) return;

    // Find the selected version's data
    const selectedVersion = versions.find(
      (version) => version._id === selectedVersionId
    );

    if (!selectedVersion) return;
    const duplicatedVersionUUID = uuidv4().slice(0, 8); // Generate a new UUID for the duplicated version
    // Prepare new version data for duplication
    const duplicatedVersionData = {
      version: {
        user_id: userId,
        book_id: bookId,
        version_name: `${selectedVersion.version_name} - Copy - ${duplicatedVersionUUID}`, // Append "Copy" to indicate duplication
        pages: selectedVersion.pages, // Copy the annotations (pages) from the selected version
        isActive: true,
      },
    };

    try {
      const result = await versionServices.createVersion(duplicatedVersionData);
      console.log("Version duplicated:", result);
      // Update versions state with the new duplicated version
      setVersions((prevVersions) => [...prevVersions, result.data]);
      setSnackbarMessage("Version duplicated successfully!");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error duplicating version:", error);
      setSnackbarMessage("Error duplicating version.");
      setSnackbarOpen(true);
    }
  };

  useEffect(() => {
    const checkCachedPDF = async () => {
      if(!pdfURL) return;
      const cache = await caches.open("appV1");
      const cachedResponse = await cache.match(pdfURL);
      setIsCached(!!cachedResponse);

      // Check localStorage to see if PDF is cached
      const cachedState = localStorage.getItem("pdfCached");
      if (cachedState === "true") {
        setIsCached(true);
      }
    };

    checkCachedPDF();

    if (propPageNumber) {
      setPageNumber(propPageNumber);
    }
  }, [isCached]);

  const handleAddToOffline = async () => {
    if (selectedVersionId) {
      try {
        const response = await versionServices.getVersionById(selectedVersionId);
        const versionData = response.data;
  
        // Transform the data to match the required structure
        const formattedAnnotations = versionData.pages.map((page) => ({
          page_index: page.page_index,
          annotations: {
            pen: {
              strokes: page.annotations.pen.strokes || [],
              color: page.annotations.pen.color || "black",
            },
            marker: {
              strokes: page.annotations.marker.strokes || [],
              color: page.annotations.marker.color || "yellow",
            },
          },
        }));
  
        // Cache the version data using the Cache API
        const cache = await caches.open('appV1');
        await cache.put(selectedVersionId, new Response(JSON.stringify(formattedAnnotations)));
  
        // Set Snackbar message and open it
        setSnackbarMessage('Version added for offline use!');
        setSnackbarOpen(true);
      } catch (error) {
        console.error("Error adding version for offline use:", error);
        setSnackbarMessage('Failed to add version for offline use. Please try again.');
        setSnackbarOpen(true);
      }
    } else {
      setSnackbarMessage('No version selected to cache.');
      setSnackbarOpen(true);
    }
  };
  
  return (
    <div>
      <label htmlFor="versionDropdown">Select Version: </label>
      <select
        id="versionDropdown"
        value={selectedVersionId}
        /* onChange={(e) => setSelectedVersionId(e.target.value)} */
        onChange={handleVersionSelect}
      >
        <option value="" disabled>
          Select a version
        </option>
        {versions.length > 0 ? (
          versions.map((version) => (
            <option key={version._id} value={version._id}>
              {version.version_name}
            </option>
          ))
        ) : (
          <option value="">No versions available</option>
        )}
      </select>

      {/* Dropdown for actions */}
      <IconButton onClick={handleMenuOpen}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => setIsModalOpen(true)}>Save New Version</MenuItem>
        {selectedVersionId && (
          <>
            <MenuItem
              onClick={() => {
                setIsEditing(true);
                setIsModalOpen(true);
              }}
            >
              Edit Version Name
            </MenuItem>
            <MenuItem onClick={handleSaveAnnotations}> Save Annotations</MenuItem>
            <MenuItem onClick={handleAddToOffline}>
              {isCached ? "Available Offline" : "Download Version"}
            </MenuItem>
            <MenuItem onClick={handleDelete}>Delete Selected Version</MenuItem>
            <MenuItem onClick={handleDuplicateVersion}>
              Duplicate Version
            </MenuItem>
          </>
        )}
      </Menu>
      
      {/* Modal for creating or editing a version */}
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSave={isEditing ? handleUpdate : handleSave}
        existingVersionName={isEditing ? selectedVersionName : ""} // Pass existing version name when editing
      />

      {/* Show edit button if a version is selected
      {selectedVersionId && (
        <>
          <button
            onClick={() => {
              setIsEditing(true);
              setIsModalOpen(true); // Open the modal for editing
            }}
          >
            Edit Version Name
          </button>

          <button
            onClick={handleSaveAnnotations}
            //disabled={!annotations.pen.color && !annotations.marker.color}
          >
            Save Annotations
          </button>

          <button onClick={() => setIsDeleteModalOpen(true)}>
            Delete Selected Version
          </button>

          <button onClick={handleDuplicateVersion}>
            Duplicate version
          </button>

          <Tooltip title={isCached ? "Version is available for offline use" : "Download Version"}>
            <button onClick={handleAddToOffline}>
              {isCached ? <OfflinePinIcon /> : <CloudDownloadIcon />}
            </button>
          </Tooltip>

        </>
      )} */}

      {/* Delete confirmation modal */}
      <DeleteModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onDelete={handleDelete}
        versionName={selectedVersionName}
      />
      
      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarMessage.includes("Error") ? "error" : "success"}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default VersionDropdown;
